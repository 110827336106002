<template>
  <div class="wrapper">
    <!-- 养老报告 -->
    <!-- <div>
        <el-button type="text" icon="el-icon-plus" @click="()=>{isplus = true }" v-if="!isplus"></el-button>
        <el-button type="text" icon="el-icon-minus" @click="()=>{isplus = false }" v-if="isplus"></el-button>
        <el-button type="primary" icon="el-icon-plus" @click="addProject()" v-if="isplus">添加</el-button>
    </div> -->
		<vxe-toolbar>
			<template v-slot:buttons>
				<vxe-button status="my-orange" @click="showSearch(false)" v-if="seachStatus">隐藏查询</vxe-button>
				<vxe-button status="my-orange" icon="fa fa-search" @click="showSearch(true)" v-if="!seachStatus">查询</vxe-button>
				<!-- <vxe-button status="my-orange" icon="" @click="goPage('add')">下载报告</vxe-button> -->
			</template>
		</vxe-toolbar>
		<div class="search" v-if="seachStatus">
			<vxe-form title-align="right" title-width="120" title-colon :data="searchData" @submit="searchEvent" @reset="searchReset">
				<vxe-form-item title="服务机构" span="8">
          <el-select v-model="searchData.assessOrgId" filterable placeholder="请选择服务机构" style="width:100%">
              <el-option
                  v-for="item in orgDatas"
                  :key="item.id"
                  :label="item.compName"
                  :value="item.id">
              </el-option>
          </el-select>
					<!-- <vxe-input v-model="searchData.assessOrgId" placeholder="请输入姓名" clearable></vxe-input> -->
				</vxe-form-item>
        <vxe-form-item title="养老顾问" span="8">
					<vxe-input v-model="searchData.workPersonName" placeholder="请输入养老顾问" clearable></vxe-input>
				</vxe-form-item>
        <vxe-form-item title="服务对象" span="8">
					<vxe-input v-model="searchData.oldPersonName" placeholder="请输入服务对象" clearable></vxe-input>
				</vxe-form-item>
				<vxe-form-item align="center" span="24">
					<vxe-button type="submit" status="my-orange" icon="fa fa-check">应用</vxe-button>
					<vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
				</vxe-form-item>
			</vxe-form>
		</div>
    <div>
      <el-table
    :data="tableData"
    border
    style="width: 100%">
    <el-table-column
      prop="oldPersonName"
      label="服务对象">
    </el-table-column>
    <el-table-column
      prop="oldPersonPhone"
      label="联系方式"
      >
    </el-table-column>
    <el-table-column
      prop="workPersonName"
      label="养老顾问"
      >
    </el-table-column>
    <el-table-column
      prop="assessOrgName"
      label="服务机构"
      >
    </el-table-column>
    <el-table-column
      prop="plan"
      label="评估方案"
      >
    </el-table-column>
    <el-table-column
      prop="preEvalResult"
      label="评估结果"
      >
    </el-table-column>
    <el-table-column
      prop="assessType"
      label="评估类型"
      >
    </el-table-column>
    <el-table-column
      prop="assessTime"
      label="评估时间"
      >
    </el-table-column>
    <el-table-column

      label="操作"
      width="100">
      <template slot-scope="scope">
        <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
      </template>
    </el-table-column>
  </el-table>
    </div>
		<!-- 分页 -->
		<div class="page">
			<vxe-pager :current-page.sync="tablePage.currentPage" :page-size.sync="tablePage.pageSize" :page-sizes="tablePage.pageSizes"
			 :total="tablePage.totalResult" :layouts="layouts" @page-change="pageChange"></vxe-pager>
		</div>
    <!-- <el-dialog title="添加人员评估" :visible.sync="dialogFormVisible" :show-close="false" :close-on-click-modal='false'>
      <el-form :model="formData" label-position="left"> 
          <el-form-item  label="姓名" prop="serviceOldPersonId" :label-width="formLabelWidth">
          <el-select filterable   v-model="formData.oldPersonId" style="width:100%" placeholder="请选择改造对象" >
              <el-option
              v-for="item in personList"
              :key="item.id"
              :label="item.personName"
              :value="item.id">
              </el-option>
          </el-select>
          </el-form-item>
          <el-form-item label="评估机构" :label-width="formLabelWidth" >
              <el-select v-model="formData.assessOrgId" filterable placeholder="请选择服务机构" style="width:100%">
              <el-option
                  v-for="item in orgDatas"
                  :key="item.id"
                  :label="item.compName"
                  :value="item.id">
              </el-option>
              </el-select>
          </el-form-item>

          <el-form-item label="评估方案" :label-width="formLabelWidth">
          <el-input v-model="formData.plan" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="评估员姓名" :label-width="formLabelWidth">
          <el-input v-model="formData.assessorName" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="感觉与沟通等级" :label-width="formLabelWidth">
            <el-select v-model="formData.measureResults.feelLevel" placeholder="请选择等级" style="width:100%" @change = changeLevel()>
              <el-option
                v-for="item in levelArr"
                :key="item.value"
                :label="item.name"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
                    <el-form-item label="精神状态" :label-width="formLabelWidth">
            <el-select v-model="formData.measureResults.mindLevel" placeholder="请选择等级" style="width:100%" @change = changeLevel()>
              <el-option
                v-for="item in levelArr"
                :key="item.value"
                :label="item.name"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
                    <el-form-item label="日常生活活动" :label-width="formLabelWidth">
            <el-select v-model="formData.measureResults.lifeLevel" placeholder="请选择等级" style="width:100%" @change = changeLevel()>
              <el-option
                v-for="item in levelArr"
                :key="item.value"
                :label="item.name"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="社会参与" :label-width="formLabelWidth">
            <el-select v-model="formData.measureResults.societyLevel" placeholder="请选择等级" style="width:100%" @change = changeLevel()>
              <el-option
                v-for="item in levelArr"
                :key="item.value"
                :label="item.name"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="初步评估结果" :label-width="formLabelWidth">
            <el-input v-model="formData.preEvalResult" autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="最终评估结果" :label-width="formLabelWidth">
            <el-input v-model="formData.finalEvalResult" autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="审核意见" :label-width="formLabelWidth">
          <el-input v-model="formData.reviewComments" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="专家意见" :label-width="formLabelWidth">
          <el-input v-model="formData.expertComments" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="评估类型" :label-width="formLabelWidth">
          <el-input v-model="formData.expertComments" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="来源" :label-width="formLabelWidth">
          <el-input v-model="formData.source" autocomplete="off"></el-input>
          </el-form-item>
          


      </el-form>
      <div slot="footer" class="dialog-footer" >
          <el-button @click="()=>{dialogFormVisible = false,
            formData={
              measureResults:{}
            }
            }">取 消</el-button>
          <el-button type="primary" @click="addElderBtn()">确 定</el-button>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
	import {
		mapState,
		mapActions,
	} from "vuex";
export default {
  data() {
			return {
        searchData:{
          oldPersonName:'',
          assessOrgId:'',
          workPersonName:'',
        },
        seachStatus: false,
        tableData:[],
        formData:{

        },
        dialogFormVisible:false,
        formLabelWidth: '120px',
        isplus:false,
        orgDatas:[],
        personList: [],
        isedit:false,
        levelArr:[
          {name:'0级',value:0},
          {name:'1级',value:1},
          {name:'2级',value:2},
          {name:'3级',value:3},
        ],
        tablePage: {
					totalResult: 0,
					currentPage: 1,
					pageSize: 10,
					align: "left",
					pageSizes: [10, 20, 50, 100, 500, 1000],
					perfect: true
				},
      }
  },
  created(){
    this.getLists()
    this.getPersonList()
    this.querySumCenterList()
  },
  computed: {
			...mapState([
				"layouts"
			]),
		},
  methods:{
    ...mapActions([
      "reportList",
      'deletereport',
      'updatereport',
      'addreport',
      'getServeOrgsListServe',
      'getHomePersonListServe',
    ]),
        // 获取服务对象
    getPersonList() {
      let params = {
        current: 1,
		size: 4000,
        param: {}
      }
      this.getHomePersonListServe(params).then(res => {
        if (res.code == 200) {
          this.personList = res.data.records;
        }
      })
    },
    searchEvent(){
      this.getLists();

    },
      // 是否显示查询功能
    showSearch(bool) {
      this.seachStatus = bool;
			this.getLists();

    },
    searchReset() {
				this.searchData = {};
				this.getLists();
			},
      // 分页功能
    pageChange(item) {
      if (item.type == "size") {
        this.tablePage.currentPage = 1;
      } else {
        this.tablePage.currentPage = item.currentPage;
      }
      this.getLists();
    },
    //查询列表
    getLists(){
      let params = {
        assessOrgId:this.searchData.assessOrgId,
        workPersonName:this.searchData.workPersonName,
        oldPersonName:this.searchData.oldPersonName,
        pageNum:this.tablePage.currentPage,
        pageSize:this.tablePage.pageSize
      }
      this.reportList(params).then(res=>{
        console.log(res);
        if(res.code==200){
          this.tableData = res.data.dataList
          this.tablePage.totalResult=res.data.total
          for (let i = 0; i < this.tableData.length; i++) {
            this.$set(this.tableData[i],'ismore',false)
          }
        }
      })
    },
    querySumCenterList(){
        this.getServeOrgsListServe({
        current:1,
        size:9999,
          param: {},
    }).then(res => {
      // console.log(res,'querySumCenterList');
      if (res.code == 200) {
        this.orgDatas = res.data.records
        console.log(this.orgDatas);
      }
      this.loading = false;
    })
    },
    //查看
    handleClick(row){
        this.$router.push({
            name: "personRecordDetail",
            query: {
                id: row.id,
            },
        });
    },
    //删除
    delProject(row){
    this.$XModal.confirm("您确定要删除该数据?").then(type => {
        if (type === "confirm") {
            let params =row.id
            this.deletereport(params).then(res=>{
                console.log(res);
                if(res.code ==200){
                    this.$message({
                    type: 'success',
                    message: '删除成功'
                    })
                this.getLists()

                }
            })
                }else{
        this.$message({
            type: 'info',
            message: '已取消删除'
        })
        }
            })
    },
    //添加确认
    addElderBtn(){
      if(this.isedit){
         let params={
          oldPersonId:this.formData.oldPersonId,
          assessOrgId:this.formData.assessOrgId,
          plan:this.formData.plan,
          assessorName:this.formData.assessorName,
          preEvalResult:this.formData.preEvalResult,
          finalEvalResult:this.formData.finalEvalResult,
          reviewComments:this.formData.reviewComments,
          expertComments:this.formData.expertComments,
          measureResults:JSON.stringify(this.formData.measureResults)

        }
      this.updatereport(params).then(res=>{
        if(res.code==200){
          this.$message.success("修改成功")
            this.formData = {
            oldPersonId:'',
            assessOrgId:'',
            plan:'',
            assessorName:'',
            preEvalResult:'',
            finalEvalResult:'',
            reviewComments:'',
            expertComments:'',
            measureResults:{
              feelLevel:'',
              mindLevel:'',
              lifeLevel:'',
              societyLevel:''
            }
          }
          this.dialogFormVisible = false
          this.getLists()

        }
      }).catch(err=>{
        console.log(err);
      })
      }else{
        // let params = this. formData
        let params={
          oldPersonId:this.formData.oldPersonId,
          assessOrgId:this.formData.assessOrgId,
          plan:this.formData.plan,
          assessorName:this.formData.assessorName,
          preEvalResult:this.formData.preEvalResult,
          finalEvalResult:this.formData.finalEvalResult,
          reviewComments:this.formData.reviewComments,
          expertComments:this.formData.expertComments,
          measureResults:JSON.stringify(this.formData.measureResults)

        }
        this.addreport(params).then(res=>{
        if(res.code==200){
          console.log(res,'添加');
          this.$message.success("添加成功")
          this.formData = {
            oldPersonId:'',
            assessOrgId:'',
            plan:'',
            assessorName:'',
            preEvalResult:'',
            finalEvalResult:'',
            reviewComments:'',
            expertComments:'',
            measureResults:{
              feelLevel:'',
              mindLevel:'',
              lifeLevel:'',
              societyLevel:''
            }
          }
          this.dialogFormVisible = false
          this.getLists()


        }
      }).catch(err=>{
        console.log(err);
      })
      }

    },
    changeLevel() {
      console.log('等级');
				 let feel = this.formData.measureResults.feelLevel;
				 let mind = this.formData.measureResults.mindLevel;
				 let life = this.formData.measureResults.lifeLevel;
				 let society = this.formData.measureResults.societyLevel;
         console.log(feel,mind,life,society);
				 if(feel !== '' && mind !== '' && life !== '' && society !== '') {
					 if(life === 0) {
						 if(feel === 0 && mind === 0 && (society === 0 || society === 1)) {
							this.formData.preEvalResult = '能力完好';
							this.formData.finalEvalResult = '能力完好'; 
						 } else if((mind >= 1 || feel >= 1) || society >= 2) {
							  this.formData.preEvalResult = '轻度失能';
							  this.formData.finalEvalResult = '轻度失能';
						 }
					 } else if(life === 1) {
						 if(mind <= 1 || feel <= 1 || society <= 1) {
							 this.formData.preEvalResult = '轻度失能';
							 this.formData.finalEvalResult = '轻度失能';
						 } else if((mind === 2 && feel === 2 && society === 2)) {
							 this.formData.preEvalResult = '中度失能';
							 this.formData.finalEvalResult = '中度失能'; 
							  
						 } else if(mind === 3 || feel === 3 || society === 3) {
							this.formData.preEvalResult = '中度失能';
							this.formData.finalEvalResult = '中度失能'; 
						 }
					 } else if(life === 2) {
						if(mind === 2 && feel === 2 && society === 2)  {
							this.formData.preEvalResult = '重度失能';
							this.formData.finalEvalResult = '重度失能';
							 
						 } else if(mind === 3 || feel === 3 || society === 3) {
							 this.formData.preEvalResult = '重度失能';
							 this.formData.finalEvalResult = '重度失能';
						 } else if(mind <= 2 || feel <= 2 || society <= 2) {
							 this.formData.preEvalResult = '中度失能';
							 this.formData.finalEvalResult = '中度失能';
							 
						 } 
					 } else if(life === 3) {
						 this.formData.preEvalResult = '重度失能';
						 this.formData.finalEvalResult = '重度失能';
					 }
				 }
			 },
  }

}
</script>

<style>
.w-100 {
    width: 100% !important;
}
</style>